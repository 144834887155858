import { cls, formatDurationAbbr, whisper } from '@/utils'
import Button from '../button'
import IconFire2 from '@/public/assets/fire2.svg'
import IconClockTimeHistory from '@haiper/icons-svg/icons/outline/clock-time-history.svg'
import useCheckInStreak from '@/hooks/useCheckInStreak'
import IconDiamond from '@/public/assets/diamond-2.svg'
import IconYes from '@/public/assets/yes.svg'
// import { useBreakpoint } from '@/hooks/useBreakPoint'
import { useCachedCheckInConfig } from '@/hooks/useCheckIn'
import { capitalize } from 'lodash-es'
import dayjs from 'dayjs'

export interface CheckInStreakChallengeProps {
  className?: string
}

export default function CheckInStreakChallenge({ className }: CheckInStreakChallengeProps) {
  const { currentCheckInStreak: nextCheckInStreak, lastCheckInStreak, checkIn, nextCheckInTime } = useCheckInStreak()

  const { data: checkInConfig } = useCachedCheckInConfig()

  const title = 'Check-in Streak'

  const now = dayjs()
  const canCheckIn = nextCheckInTime && now.isAfter(nextCheckInTime)

  const streakButton = lastCheckInStreak ? (
    <div className='bg-orange-600 hover:bg-orange-600 text-text-on-color rounded-full h-7 px-2 py-1'>
      <div className='flex items-center'>
        <IconFire2 className='text-yellow-500' />
        <span className='px-1'>
          {lastCheckInStreak} day{lastCheckInStreak > 1 ? 's' : ''} streak!
        </span>
      </div>
    </div>
  ) : null

  return (
    <div className={cls('md:p-4 flex flex-col gap-4', className)} aria-label='check-in'>
      <div className='flex w-full flex-col' aria-label='title-bar'>
        <div className='w-full flex items-center justify-between'>
          <div className='flex items-center gap-3' aria-label='left'>
            <div className='text-heading-lg font-bold tracking-45'>{title}</div>
            <div className='hidden md:flex items-center'>{streakButton}</div>
          </div>
          <div className='' aria-label='right'>
            <div className='md:hidden'>{streakButton}</div>
            <div className='hidden md:flex items-center'>
              <Button
                variant={canCheckIn ? 'primary' : 'secondary'}
                className='px-3 py-2'
                disabled={!canCheckIn}
                onClick={checkIn}
              >
                {canCheckIn ? (
                  'Check in'
                ) : (
                  <div className='flex items-center gap-1'>
                    <IconClockTimeHistory className='size-6 shrink-0 grow-0 text-icon-subdued' />
                    <span className='px-1 text-text-subdued'>
                      Check-in in {formatDurationAbbr(dayjs(), nextCheckInTime)}
                    </span>
                  </div>
                )}
              </Button>
            </div>
          </div>
        </div>
        <div className='text-text-subdued text-body-md tracking-15'>
          Streaks reset every 7 days and restart immediately if interrupted.
        </div>
      </div>
      <div className='flex w-full justify-center gap-3 flex-wrap md:flex-nowrap min-h-20' aria-label='jobs/days'>
        {checkInConfig?.map((configItem, index) => {
          const active = index + 1 === nextCheckInStreak
          const checked = index + 1 <= lastCheckInStreak
          const Icon = checked ? IconYes : IconDiamond
          const canCheckIn = active && !checked
          return (
            <div
              key={[configItem, index].join()}
              className={cls(
                'rounded-lg flex flex-col p-0.5 gap-0.5 border border-b-2 flex-1 tracking-15 text-body-md w-18 min-w-18 shrink-0 grow-0',
                checked && 'bg-surface-active',
                active && 'bg-surface-primary-hover border-surface-primary-hover text-text-on-color',
                canCheckIn && 'cursor-pointer',
              )}
              onClick={canCheckIn ? checkIn : undefined}
            >
              <span
                className={cls(
                  'text-center text-text-subdued',
                  active && 'text-text-on-color',
                  checked && active && 'font-bold',
                )}
              >
                {checked && active ? 'Checked' : capitalize(configItem.name)}
              </span>
              <div className='p-1 pb-0 flex flex-col items-center text-center'>
                <Icon className='size-6 shrink-0 grow-0' />
                <span
                  className={cls(
                    'text-body-lg font-bold tracking-32',
                    checked && 'text-text-subdued',
                    active && 'text-text-on-color',
                  )}
                >
                  {configItem.reward_amount}
                </span>
              </div>
            </div>
          )
        })}
      </div>
      <div className='w-full flex md:hidden items-center justify-center'>
        <Button
          variant={canCheckIn ? 'primary' : 'secondary'}
          className='px-3 py-2 w-full'
          disabled={!canCheckIn}
          onClick={checkIn}
        >
          {canCheckIn ? (
            'Check in'
          ) : (
            <div className='flex items-center gap-1'>
              <IconClockTimeHistory className='size-6 shrink-0 grow-0 text-icon-subdued' />
              <span className='px-1 text-text-subdued'>Check-in in {formatDurationAbbr(dayjs(), nextCheckInTime)}</span>
            </div>
          )}
        </Button>
      </div>
    </div>
  )
}
